.container {
    background-color: #99D1F2;
    /* height: 1000px; */
    padding: 16px;
}

.innerContainer {
    background-color: #1B1B1B;
    height: 100%;
    border-radius: 30px;
    padding: 28px;
}

.phoneImage {
    margin-top: 48px;
    width: 100%;
    height: 100%;
}
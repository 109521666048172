.container {
    background-color: #FFFFFF;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    color: #616161;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    text-align: center;
    margin: 0;
    margin-top: 38px;
    margin-bottom: 10px;
}

.text {
    color: #1B1B1B;
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin: 0;
}

.miniContainer {
    background-color: #F8F8F8;
    width: calc(100% - 48px);
    margin: 54px 24px;
    height: 200px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border-radius: 16px;
    padding-left: 24px;
}

.miniIcon {
    width: 48px;
    height: 48px;
    margin-top: 24px;

}

.miniTitle {
    color: #1B1B1B;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    margin-top: 10px;
}

.miniText {
    color: #616161;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
        padding-right: 24px;

    margin-top: -10px;
}
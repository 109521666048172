.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
}

.imageSection {
    padding: 44px 16px;
    width: calc(100% - 32px);
    overflow-y: auto;
}

.body {
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
}

.stylesTitle {
    color: #99DBF2;
    font-family: 'Inter', sans-serif;
    font-size: 44px;
    text-align: center;
    margin: 0;
    margin-bottom: 16px;
}

.text {
    color: #ACACAC;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    text-align: center;
    margin: 0;
    padding: 0 16px;
}


.stylesImage {
    width: 215px;
    height: 68px;
    object-fit: contain;
    align-self: center;
    padding-top: 32px;
    padding-bottom: 54px;
}
body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #1B1B1B;
  }

.container {
    background-color: #1B1B1B;
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0px;
    /* display: flex; */
    width: 100%;
    max-width: 480px;
    min-height: 100vh;
    overflow: hidden;
}

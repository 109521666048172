.container {
    margin: 0px 20px 0px 20px;
    height: 98px;
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.text {
    color: white;
    font-size: 18px;
    margin-left: 16px;
    font-family: 'Inter', sans-serif;
    color: white;
    font-weight: bold;
}

.image {
    width: 54px;
    height: 54px;
    border-radius: 10px;
}

.logoSection {
    display: flex;
    align-items: center;
}

.linksSection {
    display: flex;
    gap: 20px;
}

.link {
    color: #ACACAC;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    text-decoration: none;
}
.container {
    /* margin: 40px 20px 0px 20px; */
    padding: 40px 0px 0px 0px;
    height: 54px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text {
    color: white;
    font-size: 18px;
    margin-left: 16px;
    font-family: 'Inter', sans-serif;
    color: white;
    font-weight: bold;
}

.image {
    margin-left: 20px;
    width: 54px;
    height: 54px;
    border-radius: 10px;
}

.contactButton {
    margin-left: auto;
    margin-right: 20px;
    width: 120px;
    height: 42px;
    background-color: white;
    color: black;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.contactButton:hover {
    background-color: #f0f0f0;
}

.container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    font-size: 44px;
    text-align: center;
    margin: 0;
    margin-bottom: 16px;
}

.text {
    color: #ACACAC;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    text-align: center;
    margin: 0;
    padding: 0 16px;
}


.storeLink {
    margin-top: 56px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.storeImage {
    width: 145px;
    height: 42px;
    object-fit: contain;
}